import Alpine from 'alpinejs';
import collect from 'collect.js';
import flatpickr from 'flatpickr';
import rangePlugin from 'flatpickr/dist/plugins/rangePlugin';
import Breakpoints from './breakpoints';

window.updateQueryParams = (params) => {
    const queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    params.forEach((paramObject) => {
        queryParams.set(paramObject.key, paramObject.value);
    });

    // Replace current querystring with the new one.
    window.history.pushState(null, null, `?${queryParams.toString()}`);
};

Alpine.data('tabs', (urlKey) => {
    return {
        type: new URLSearchParams(window.location.search).get(urlKey),
        updateUrl: (type) => {
            window.updateQueryParams([{ key: urlKey, value: type }]);
        },
    };
});

Alpine.data('date_field', (liveWireValue = null) => {
    return {
        flatpickrInstance: null,
        value: liveWireValue,
        initFlatPickr() {
            const self = this;

            this.flatpickrInstance = flatpickr(this.$refs.date_input, {
                allowInput: true,
                altInput: true,
                altFormat: 'd/m/Y',
                format: 'd/m/Y',
                static: true,
            });
        },
        init() {
            this.initFlatPickr();
        },
    };
});

Alpine.data('dropdown_filter', (label, options = [], liveWireValue) => {
    return {
        open: false,
        label,
        options,
        value: liveWireValue,
        close() {
            this.open = false;
        },
        toggle() {
            this.open = !this.open;
        },
        updateValue(newValue) {
            this.value = newValue;
        },
        get activeLabel() {
            if (!this.value) {
                return this.label;
            }
            let returnLabel = 'Unknown';

            const active = this.options.find((e) => { return e.value === this.value; });

            if (active) {
                returnLabel = active.label;
            }

            return `${this.label}: ${returnLabel}`;
        },
    };
});

Alpine.data('dropdown_datefilter', (label, liveWireStartDateValue, liveWireEndDateValue) => {
    return {
        open: false,
        label,
        startDate: null,
        endDate: null,
        liveWireStartDateValue,
        liveWireEndDateValue,
        flatpickrInstance: null,
        initFlatPickr() {
            const self = this;

            this.startDate = this.liveWireStartDateValue;
            this.endDate = this.liveWireEndDateValue;

            this.flatpickrInstance = flatpickr(this.$refs.start_date, {
                mode: 'range',
                dateFormat: 'd/m/Y',
                static: true,
                plugins: [
                    new rangePlugin({ // eslint-disable-line
                        input: this.$refs.end_date,
                    }),
                ],
                onClose(selectedDates, dateStr) {
                    self.setDate('startDate', selectedDates[0]);
                    self.setDate('endDate', selectedDates[1]);
                },
            });

            // If we don't have a start date and end date, either this is a fresh init
            // or we were initialised with null values (clear filters) and should empty the instance
            if (!this.startDate && !this.endDate) {
                this.flatpickrInstance.clear();
            }
        },
        init() {
            this.initFlatPickr();
        },
        setDate(property, date) {
            if (!date) {
                return null;
            }

            const fullDate = date.getDate().toString().padStart(2, '0');
            const fullMonth = (date.getMonth() + 1).toString().padStart(2, '0'); // javscript dates are 0 index based
            const fullYear = date.getFullYear();

            this[property] = `${fullDate}/${fullMonth}/${fullYear}`;
        },
        close() {
            this.open = false;
        },
        toggle() {
            this.open = !this.open;
        },
        save() {
            this.liveWireStartDateValue = this.startDate;
            this.liveWireEndDateValue = this.endDate;

            this.close();
        },
        get activeLabel() {
            if (!this.value) {
                return this.label;
            }
            const returnLabel = 'Unknown';

            return `${this.label}: ${returnLabel}`;
        },
    };
});

Alpine.data('modal', () => {
    return {
        open: false,
        openModal(event) {
            this.open = true;
            this.$wire.emit('openModal', event.detail);
        },
        closeModal() {
            this.open = false;
            this.$wire.emit('closeModal');
        },
    };
});

Alpine.data('declaration_form', (defaultSection, livewireSections) => {
    return {
        defaultSection,
        activeSection: defaultSection,
        paginationSections: livewireSections,
        init() {
            this.updateSectionFromUrl();
        },
        get currentPageIndex() {
            return collect(this.paginationSections).search((item, key) => {
                return item.key === this.activeSection;
            });
        },
        get currentSection() {
            return this.paginationSections[this.currentPageIndex];
        },
        get previousSection() {
            return collect(this.paginationSections).get(this.currentPageIndex - 1);
        },
        get nextSection() {
            return collect(this.paginationSections).get(this.currentPageIndex + 1);
        },
        updateQueryParam() {
            window.updateQueryParams([{ key: 'section', value: this.activeSection }]);
        },
        setActiveSection(newSection) {
            this.activeSection = newSection;

            this.updateQueryParam();
        },
        updateSectionFromUrl() {
            const urlSection = new URLSearchParams(window.location.search).get('section');

            if (urlSection) {
                this.activeSection = urlSection;

                return;
            }

            this.activeSection = this.defaultSection;
        },
        previousPage() {
            if (!this.previousSection) {
                return;
            }

            this.activeSection = this.previousSection.key;

            this.updateQueryParam();
        },
        nextPage() {
            if (!this.nextSection) {
                return;
            }

            this.activeSection = this.nextSection.key;

            this.updateQueryParam();
        },
    };
});

Alpine.data('change_requests', () => {
    return {
        open: false,
        allowHidingAndShowing: false,
        init() {
            this.handleBreakpointChange();
        },
        toggle() {
            if (this.allowHidingAndShowing) {
                this.open = !this.open;
            }
        },
        handleBreakpointChange() {
            if (Breakpoints.atBreakpointOrHigher('2xl')) {
                this.allowHidingAndShowing = false;
                this.open = true;
            }

            if (Breakpoints.atBreakpointOrLower('xl')) {
                this.allowHidingAndShowing = true;
                this.open = false;
            }
        },
    };
});

Alpine.data('renewal_form', (renewalOpen) => {
    return {
        open: false,
        init() {
            this.open = renewalOpen;
        },
        toggle() {
            this.open = !this.open;
        },
    };
});

window.Alpine = Alpine;

Alpine.start();
